import styled from "styled-components"
import media from "styled-media-query"

export const LayoutWrapper = styled.section`
  display: flex;
  flex-flow: row wrap;
`

export const LayoutMain = styled.main`
  min-height: 100vh;
  padding: 0 3.75rem 0 3.75rem;
  max-width: 1500px;
  width: 100%;
  margin: auto;
  margin-top: 8rem;

  ${media.lessThan("large")`
    padding: 0;
  `}
`
