import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input, textarea, button {
    border: none;
    outline: none;
  }

  a {
    text-decoration: none;
  }

  :root {
    --fontSerif: "Lora", Cambria, "Times New Roman", serif;
    --fontSansSerif: "Lato", Helvetica, Roboto, Arial, sans-serif;
  }

  body {
    --purple-accent: #7975f5;
    --purple: #5c58db;
    --purple-light: #4d2ee0;
    --yellow: #f4be47;
    --red: #f44747;
    --green: #3dec9b;
    --gradient1: linear-gradient(185deg,#63baf8,#4d2ee0);
    --gradient2: linear-gradient(40deg,#63baf8,#4d2ee0);
    --gradient3: linear-gradient(40deg,#63baf8,#4d2ee0,var(--main-color));

    --uiDesign: linear-gradient(to top right, #b224ef 0%, #7579ff 100%);
    --desembaracandoWebEspecial: linear-gradient(75deg,#fdaf6d, var(--yellow));
    --frontend: linear-gradient( 105.2deg, rgb(78, 230, 255) 11.2%, rgb(85, 71, 253) 117.9% );
    --desembaracandoWeb: linear-gradient( 105.2deg,  rgb(255, 78, 78) 11.2%, rgb(253, 176, 71) 117.9% );
  }

  body.dark {
    --bg-body-color: #2b2e35;
    --bg-color: #202029;
    --main-color: #FFFFFFDB;
    --contrast-color: #FEFDFB;
    --text-color: rgba(255, 255, 255, 0.8);

    --cardHoverBg: #5c58db91;

    --headerShadow: 0 3px 12px #1d193145;
    --elevation1: 0 2px 12px #2b2935;
  }

  body.light {
    --bg-body-color: #F8F9FB;
    --bg-color: #fff;
    --main-color: #2b2e35;
    --contrast-color: #4d2ee0;
    --text-color: #494d54;

    --cardHoverBg: #4d2ee017;

    --headerShadow: 0 3px 12px #24203814;
    --elevation1: 0 2px 8px #4735a04a;
  }

  body {
    transition: 150ms ease-in-out;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--purple);
  }

  h3,
  h4,
  h5,
  p,
  li,
  label {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  h1 {
    font-size: 4.3rem;
    line-height: 1;
  }

  p {
    font-size: 1.7rem;
    line-height: 1.3;
  }

  a {
    font-size: 1.4rem;
    line-height: 1.3;
    text-decoration: none;
  }

  body {
    background: var(--bg-body-color);
    color: #fafafb;
    line-height: 1.1;
    font-size: 100%;
    font-family: var(--fontSansSerif);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  #nprogress .bar {
    top: 120px !important;
    z-index: 10000;
  }
`

export default GlobalStyles
