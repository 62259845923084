const getColorTheme = () => {
  try {
    const theme = window !== "undefined" && window.__theme

    return theme === "light" ? "#fff" : "#32b2e35"
  } catch (e) {}
}

export const getTheme = () => {
  try {
    const theme = window !== "undefined" && window.__theme

    return theme
  } catch (e) {}
}

export default getColorTheme
