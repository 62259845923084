import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type SEOProps = {
  lang?: string
  meta?:
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }
    | ConcatArray<
        | { name: string; content: any; property?: undefined }
        | { property: string; content: any; name?: undefined }
      >
  title?: string
  image?: string
  description?: string
  absoluteImgUrl?: boolean
}

function SEO({
  lang = "",
  meta = [],
  title = "",
  image = "",
  description = "",
  absoluteImgUrl = false,
}: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const url = site.siteMetadata.siteUrl
  const ogImage = absoluteImgUrl
    ? image
    : `${url}${image || "/assets/img/cover.png"}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:image:src`,
          content: ogImage,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Lora:wght@400;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

export default SEO
