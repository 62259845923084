import styled from "styled-components"
import media from "styled-media-query"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { Sun } from "@styled-icons/feather/Sun"
import { Moon } from "@styled-icons/feather/Moon"

export const Header = styled.header`
  width: 100%;
  box-shadow: var(--headerShadow);
  padding: 1.5rem 12rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-color);
  position: fixed;

  ${media.lessThan("large")`
    margin-left: 0;
    margin-right: 0;
    padding: 1.5rem 10rem;
    max-width: 100vw;
    flex-flow: row wrap;
    justify-content: center;
  `}

  ${media.lessThan("medium")`
    margin-left: 0;
    margin-right: 0;
    padding: 1.5rem 5rem;
    max-width: 100vw;
    flex-flow: row wrap;
    justify-content: center;
  `}

  ${media.lessThan("small")`
    padding: 1.5rem 4rem;
  `}
`

export const Logo = styled(AniLink)`
  user-select: none;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--fontSerif);
  background: linear-gradient(185deg, #63baf8, #4d2ee0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: var(--main-color);
  line-height: 1.3;

  ${media.lessThan("large")`
    margin-bottom: 12px;
  `}
`

export const MenuNav = styled.nav`
  display: flex;
  flex-flow: row nowrap;

  ${media.lessThan("large")`
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
  `}
`

export const MenuItem = styled.li`
  font-weight: 500;
  color: var(--main-color);
  margin-left: 1.5rem;
  text-align: center;
  position: relative;
  font-family: var(--fontSansSerif);

  ${media.lessThan("large")`
    margin-bottom: 10px;
  `}

  ${media.lessThan("large")`
    margin-left: 0;
  `}
`

export const MenuOuterLink = styled.a`
  font-size: 1.25rem;
  color: var(--contrast-color);
  font-family: var(--fontSansSerif);
  margin-left: 3rem;
  text-align: center;
  position: relative;

  &.activePage {
    color: #fff;
    background-color: var(--purple);
    padding: 2px 3px;
    /* text-shadow: 0 1px 3px #302a50ab; */
    text-shadow: 0 1px 1px #302a5054;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    /* background-color: var(--main-color); */
    background-color: var(--contrast-color);
    transform-origin: 0 100%;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover:before {
    transform: scaleX(1);
  }

  ${media.lessThan("medium")`
    margin-left: 0;
    padding: 10px;
  `}
`

export const MenuLink = styled(AniLink)`
  font-size: 1.25rem;
  color: var(--contrast-color);
  font-family: var(--fontSansSerif);
  margin-left: 3rem;
  text-align: center;
  position: relative;

  &.activePage {
    color: #fff;
    background-color: var(--purple);
    padding: 2px 3px;
    /* text-shadow: 0 1px 3px #302a50ab; */
    text-shadow: 0 1px 1px #302a5054;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    /* background-color: var(--main-color); */
    background-color: var(--contrast-color);
    transform-origin: 0 100%;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover:before {
    transform: scaleX(1);
  }

  ${media.lessThan("medium")`
    margin-left: 0;
    padding: 10px;
  `}
`

export const Light = styled(Moon)`
  color: var(--contrast-color);
  padding: 5px;
  margin-left: 5rem;
  width: 32px;
  stroke-width: 2px;
  border-radius: 4px;
  cursor: pointer;
  transition: 150ms ease-in-out;

  &:hover {
    background: #ffffff14;
  }

  ${media.lessThan("medium")`
    margin-left: 0;
    position: fixed;
    background: var(--bg-color);
    top: 150px;
    right: 15px;
    padding: 10px;
    width: 40px;
    box-shadow: var(--elevation1);
  `}
`

export const Dark = styled(Sun)`
  color: var(--contrast-color);
  padding: 5px;
  margin-left: 5rem;
  width: 32px;
  stroke-width: 2px;
  border-radius: 4px;
  cursor: pointer;
  transition: 150ms ease-in-out;

  &:hover {
    background: #ffffff14;
  }

  ${media.lessThan("medium")`
    margin-left: 0;
    position: fixed;
    background: var(--bg-color);
    top: 150px;
    right: 15px;
    padding: 10px;
    width: 40px;
    box-shadow: var(--elevation1);
  `}
`
