import React, { useState, useEffect } from "react"
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu"

import "@szhsin/react-menu/dist/index.css"

import getColorTheme from "../../utils/getColorTheme"

import * as S from "./styled.js"

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const Header = ({ isBlog }) => {
  const [theme, setTheme] = useState(null)

  const isDarkMode = theme === "dark"

  useEffect(() => {
    setTheme(window.__theme)
    window.__onThemeChange = () => setTheme(window.__theme)
  }, [])

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const links = [
    {
      id: 1,
      href: "/sobre-mim",
      isOuter: false,
      label: "Sobre Mim",
    },
    {
      id: 2,
      href: "/portfolio",
      isOuter: false,
      label: "Portfólio",
    },
    {
      id: 3,
      href: "/blog",
      isOuter: false,
      label: "Blog",
      state: { isBlog: true },
    },
    {
      id: 4,
      href: "https://emanuelpna.github.io/css-utilities/",
      isOuter: true,
      label: "CSS Utilities",
    },
  ]

  return (
    <S.Header isBlog={isBlog}>
      <S.Logo cover direction="down" bg={getColorTheme()} duration={0.6} to="/">
        Emanuel Andrade
      </S.Logo>
      <S.MenuNav>
        {windowDimensions.width > 768 &&
          links.map(link =>
            !link.isOuter ? (
              <S.MenuLink
                key={link.id}
                cover
                direction="up"
                bg={getColorTheme()}
                duration={0.6}
                to={link.href}
                activeClassName="activePage"
                state={link.state}
              >
                {link.label}
              </S.MenuLink>
            ) : (
              <S.MenuOuterLink
                key={link.id}
                href={link.href}
                target="_blank"
                activeClassName="activePage"
              >
                {link.label} &#8599;
              </S.MenuOuterLink>
            )
          )}

        {windowDimensions.width <= 768 && (
          <Menu
            styles={{
              background: "var(--bg-color)",
              color: "var(--text-color)",
            }}
            menuButton={
              <MenuButton
                styles={{
                  background: "var(--bg-color)",
                  borderRadius: " 0.25rem",
                  border: "1px solid var(--purple)",
                  color: "var(--purple)",
                  margin: "auto",
                  hover: {
                    background: "var(--purple)",
                    color: "var(--text-color)",
                  },
                  active: {
                    background: "var(--purple)",
                    color: "var(--text-color)",
                  },
                }}
              >
                Abrir Menu
              </MenuButton>
            }
          >
            {links.map(link =>
              !link.isOuter ? (
                <MenuItem
                  key={link.id}
                  styles={{
                    background: "var(--bg-color)",
                  }}
                >
                  <S.MenuLink
                    cover
                    direction="up"
                    bg={getColorTheme()}
                    duration={0.6}
                    to={link.href}
                    activeClassName="activePage"
                    state={link.state}
                  >
                    {link.label}
                  </S.MenuLink>
                </MenuItem>
              ) : (
                <MenuItem
                  key={link.id}
                  styles={{
                    background: "var(--bg-color)",
                  }}
                >
                  <S.MenuOuterLink
                    href={link.href}
                    target="_blank"
                    activeClassName="activePage"
                  >
                    {link.label} &#8599;
                  </S.MenuOuterLink>
                </MenuItem>
              )
            )}
          </Menu>
        )}

        {!isDarkMode && (
          <S.Light
            onClick={() =>
              window.__setPreferredTheme(isDarkMode ? "light" : "dark")
            }
          />
        )}
        {isDarkMode && (
          <S.Dark
            onClick={() =>
              window.__setPreferredTheme(isDarkMode ? "light" : "dark")
            }
          />
        )}
      </S.MenuNav>
    </S.Header>
  )
}
export default Header
