import styled from "styled-components"
import media from "styled-media-query"

import { Github } from "@styled-icons/feather/Github"
import { Behance } from "@styled-icons/boxicons-logos/Behance"
import { Instagram } from "@styled-icons/boxicons-logos/Instagram"
import { LinkedinSquare } from "@styled-icons/boxicons-logos/LinkedinSquare"

export const Footer = styled.footer`
  width: 40%;
  text-align: center;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 2rem;

  .Space {
    display: block;
    width: 20px;
  }

  ${media.lessThan("small")`
    width: 100%;
    margin-top: 3rem;
    padding: 1.5rem 1rem;
  `}
`

export const Icons = styled.span`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  fill: var(--main-color);
`

export const InstagramIcon = styled(Instagram)`
  margin-right: 12px;
  width: 50px;

  a:hover & {
    fill: var(--purple-light);
  }
`

export const BehanceIcon = styled(Behance)`
  margin-right: 12px;
  width: 50px;

  a:hover & {
    fill: var(--purple-light);
  }
`

export const LinkedinIcon = styled(LinkedinSquare)`
  margin-right: 12px;
  width: 50px;

  a:hover & {
    fill: var(--purple-light);
  }
`

export const GithubIcon = styled(Github)`
  margin-right: 12px;
  stroke-width: 2px;
  width: 40px;

  a:hover & {
    stroke: var(--purple-light);
  }
`

export const PortLinks = styled.a`
  color: var(--main-color);
  font-family: var(--fontSerif);
  font-weight: 700;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 1.3rem;

  &:hover {
    color: var(--purple-light);
  }

  ${media.lessThan("large")`
    margin-bottom: 12px;
  `}
`
export const Copyright = styled.p`
  color: var(--text-color);
  margin-top: 10px;
  font-size: 1.1rem;
`
