import React from "react"

import * as S from "./styled.js"

const Header = () => {
  return (
    <S.Footer>
      <S.Icons>
        <S.PortLinks
          href="https://www.instagram.com/emanuel.pna"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <S.InstagramIcon />
          <span>instagram</span>
        </S.PortLinks>
       
        <S.PortLinks
          href="https://www.linkedin.com/in/emanuelpna/"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <S.LinkedinIcon />
          <span>linkedin</span>
        </S.PortLinks>

        <S.PortLinks
          href="https://github.com/Emanuelpna"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <S.GithubIcon />
          <span>github</span>
        </S.PortLinks>

        <S.PortLinks
          href="https://www.behance.net/emanuelpna"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <S.BehanceIcon />
          <span>behance</span>
        </S.PortLinks>
      </S.Icons>
      <S.Copyright>
        Feito com{" "}
        <span role="img" title='amor' aria-label="coração" aria-labelledby="ícone de coração">
          💖
        </span>{" "}
        e GatsbyJs
      </S.Copyright>
    </S.Footer>
  )
}
export default Header
